
.wrapper form {
  max-width: 320px;
}

.button {
  min-width: 200px;
}

.forgotPassword {
  cursor: pointer;
  font-size: 12px;
  color: $primary;
  margin-bottom: 20px;
  margin-top: -10px;
}

.introText {
  font-size: 14px;
  margin-bottom: 20px;
}
