.wrapper {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  margin: 0 auto;
  h3 {
    width: 100%;
  }
}

.wrapper form {
  margin: 0 auto;
}

.button {
  min-width: 200px;
}
