.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
}
.leftContent {
  a {
    color: $primary;
  }
}
.rightContent {
  a {
    margin-left: 20px;
    color: $primary;
  }
}
