.button {
  cursor: pointer;
  background-color: #424242;
  font-family: inherit;
  border: none;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 22px;
  font-size: 15px;
  border: 1px solid #e9ebeb;
  border-bottom: 1px solid #e1e3e3;
  border-radius: 4px;
  background-color: #fff;
  color: rgba(14,30,37,.87);
  box-shadow: 0 2px 4px 0 rgba(14,30,37,.12);
  transition: all .2s ease;
  transition-property: background-color,color,border,box-shadow;
  outline: 0;
  font-weight: 500;
  &.hasIcon {
    .buttonText {
      padding-top: 3px;
    }
  }
  &:hover, &:focus {
    box-shadow: 0 8px 12px 0 rgba(233,235,235,.16), 0 2px 8px 0 rgba(0,0,0,.08);
    text-decoration: none;
  }
  /* kind styles */
  &.primary {
    background: $primary;
    color: #fff;
    border-color: transparent;
    &:hover, &:focus {
      background: $primaryHover;
    }
  }
  &.secondary {
    background: #424242;
    color: #fff;
  }
  &.tertiary {
    background: #fff;
    color: rgba(14,30,37,.87);
    &:hover, &:focus {
      background-color: #f5f5f5;
      color: rgba(14,30,37,.87);
    }
  }
  &.danger {
    background: $danger;
    color: #fff;
    &:hover, &:focus {
      background: $dangerHover;
    }
  }
  &:focus {
    outline: none;
  }
}

a.button {
  display: inline-flex;
  text-decoration: none;
  color: #fff;
  border: none !important;
}
.icon {
  margin-right: 10px;
  fill: #fff;
  margin-left: -10px;
}
