$heroWidth: 980px;
$heroLeftWidth: 350px;

.headline {
  color: $primary;
}

.hero {
  display: flex;
  width: $heroWidth;
  margin: 50px auto;
  justify-content: space-between;
}
.heroLeft {
  width: $heroLeftWidth;
}
.heroRight {
  width: calc($heroWidth - $heroLeftWidth);
}
.reminderItem {
  font-weight: 200;
  padding: 20px;
  padding-left: 25px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
  border-bottom: 1px solid #d9d9d9;
}
