/*
 * box model like it should be
 *
 * http://www.paulirish.com/2012/box-sizing-border-box-ftw/
 */
html { box-sizing: border-box; }

html {
  font-size: 62.5%; /* html is set to 62.5% so that all the REM measurements throughout  are based on 10px sizing. So basically 1.5rem = 15px :) */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/*
 * kill document defaults margin & padding. We all do that all the time, right ?
 */
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

/*
 * page styles
 */
html {
  min-height: 100%;
  background: #fff;
}

body {
  font-size: 14px;
  color: #333;
  background: #f2f4f8;
}

/*
 * Set default height to viewport to avoid jumpy UI
 */
#root {
  min-height: 100vh;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

body {
  font-size: $fontSize;
  color: #333;
  background: #f2f4f8;
  font-family: "Open Sans", sans-serif;
}
h1, h2, h3 {
  font-weight: 600;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
}
h1 { font-size: 3.0rem; line-height: 1.2; letter-spacing: -.05rem;}
h2 { font-size: 2.5rem; line-height: 1.25; letter-spacing: -.03rem; }
h3 { font-size: 2.0rem; line-height: 1.3;  letter-spacing: -.025rem; }
h4 { font-size: 1.8rem; line-height: 1.35; letter-spacing: -.02rem; }
h5 { font-size: 1.6rem; line-height: 1.5;  letter-spacing: -.02rem; }
h6 { font-size: 1.2rem; line-height: 1.6;  letter-spacing: 0; }
p {  letter-spacing: 0; }


a, a:hover {
  text-decoration: none;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}
