.wrapper {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper form {
  margin: 0 auto;
  max-width: 320px;
}
