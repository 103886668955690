
.wrapper {
  display: inline-block;
  fill: inherit;
  position: relative;
}
.icon {
  display: flex;
  width: $iconDefault;
  height: $iconDefault;
  &.spinning {
    animation: reverseSpin 1s infinite linear;
  }
}
.hasClick {
  cursor: pointer;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes reverseSpin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
